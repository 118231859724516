define('fakturama/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    showLayout: true,
    session: Ember.inject.service(),

    beforeModel: function beforeModel() {
      return this.get('session').setup();
    },

    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
});