define('fakturama/controllers/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    firebase: Ember.inject.service('firebase'),

    numerationTypes: null,

    isDeleteModalVisible: false,

    errors: Ember.computed.readOnly('model.errors'),

    actions: {
      save: function save() {
        var _this = this;

        var model = this.get('model');
        model.set('isSubmitted', true);
        model.save().then(function () {
          return _this.transitionToRoute('invoices.index');
        });
      },

      showDeleteModal: function showDeleteModal() {
        this.set('isDeleteModalVisible', true);
      },

      dismissDeleteModal: function dismissDeleteModal() {
        this.set('isDeleteModalVisible', false);
      },

      importDatabase: function importDatabase(data) {
        var _this2 = this;

        var _get$getProperties = this.get('firebase').getProperties(['url', 'userId', 'token']),
            url = _get$getProperties.url,
            userId = _get$getProperties.userId,
            token = _get$getProperties.token;

        var uri = url + '/' + userId + '.json?auth=' + token;

        Ember.$.ajax(uri, { type: 'PUT', data: data }).done(function () {
          _this2.get('application').clearCache();
          _this2.transitionToRoute('invoices');
        });
      },
      deleteDatabase: function deleteDatabase() {
        var _this3 = this;

        var _get$getProperties2 = this.get('firebase').getProperties(['url', 'userId', 'token']),
            url = _get$getProperties2.url,
            userId = _get$getProperties2.userId,
            token = _get$getProperties2.token;

        var uri = url + '/' + userId + '.json?auth=' + token;

        Ember.$.ajax(uri, { type: 'DELETE' }).done(function () {
          _this3.get('application').clearCache();
          _this3.transitionToRoute('invoices');
        });
      }
    }
  });
});