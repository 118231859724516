define('fakturama/lib/firebase_auth', ['exports', 'fakturama/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _config$APP = _environment.default.APP,
      FIREBASE = _config$APP.FIREBASE,
      projectId = _config$APP.FIREBASE.projectId;
  var _window = window,
      firebase = _window.firebase;

  var app = firebase.initializeApp(FIREBASE);

  function delegateMethod(name) {
    return function () {
      var _get;

      return (_get = this.get('content'))[name].apply(_get, arguments);
    };
  }

  exports.default = Ember.Object.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('content', app.auth(projectId));
      this.set('googleProvider', new firebase.auth.GoogleAuthProvider());
    },


    onAuthStateChanged: delegateMethod('onAuthStateChanged'),
    signInAnonymously: delegateMethod('signInAnonymously'),
    signInWithGoogle: function signInWithGoogle() {
      var content = this.get('content');
      var provider = this.get('googleProvider');
      provider.addScope('email');
      provider.addScope('profile');
      return content.signInWithPopup(provider);
    },

    signOut: delegateMethod('signOut')
  });
});