define('fakturama/models/account', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    bankName: attr(),
    swift: attr(),
    number: attr(),
    description: attr(),
    name: Ember.computed('bankName', 'description', 'number', function () {
      if (this.get('description')) {
        return this.get('description');
      } else {
        return [this.get('bankName'), this.get('number')].compact().join(' ');
      }
    })
  });
});