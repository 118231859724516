define('fakturama/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    isAnonymous: false,
    uid: function uid(i) {
      return 'uid-' + i;
    },
    displayName: function displayName() {
      return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
    },
    email: function email() {
      return this.displayName.underscore() + '@example.com';
    }
  });
});