define('fakturama/controllers/accounts/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    accounts: Ember.computed('content.[]', function () {
      return this.get('content').filterBy('isNew', false);
    })
  });
});