define('fakturama/helpers/format-cents', ['exports', 'fakturama/lib/format_cents'], function (exports, _format_cents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.helper(function (value, _ref) {
    var _ref$options = _ref.options;
    _ref$options = _ref$options === undefined ? {} : _ref$options;
    var _ref$options$hash = _ref$options.hash,
        hash = _ref$options$hash === undefined ? {} : _ref$options$hash;

    var precision = (hash || {}).precision || 2;

    return (0, _format_cents.default)(value, precision);
  });
});