define('fakturama/components/drop-down', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'select',

    attributeBindings: ['id'],

    classNames: ['form-control'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Pass first option's value to onSelect if value is undefined
      if (this.get('value') === undefined) {
        var valueKey = this.get('valueKey');
        var model = this.get('model.firstObject');
        if (model) {
          var value = model.get(valueKey);
          Ember.run.next(this, function () {
            this.get('onSelect')(value);
          });
        }
      }
    },
    change: function change(event) {
      var value = event.target.value;
      this.get('onSelect')(value);
    },


    options: Ember.computed('model.@each', 'labelKey', 'valueKey', function () {
      var label = this.get('labelKey');
      var value = this.get('valueKey');
      return this.getWithDefault('model', []).map(function (item) {
        return { value: item.get(value), label: item.get(label) };
      });
    })
  });
});