define('fakturama/forms/account', ['exports', 'ember-validations', 'fakturama/mixins/form'], function (exports, _emberValidations, _form) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectProxy.extend(_emberValidations.default, _form.default, {
    validations: {
      number: {
        presence: {
          if: 'isSubmitted',
          message: 'nie może być pusty'
        }
      }
    },

    id: Ember.computed.oneWay('model.id'),
    bankName: Ember.computed.oneWay('model.bankName'),
    swift: Ember.computed.oneWay('model.swift'),
    number: Ember.computed.oneWay('model.number'),
    description: Ember.computed.oneWay('model.description')
  });
});