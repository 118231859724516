define('fakturama/mixins/item-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    netAmount: Ember.computed('netPrice', 'quantity', function () {
      return Math.round(this.get('netPrice') * this.get('quantity'));
    }),

    taxAmount: Ember.computed('netAmount', 'taxRate.value', function () {
      return Math.round(this.get('netAmount') * this.get('taxRate.value') / 100);
    }),

    grossAmount: Ember.computed('netAmount', 'taxAmount', function () {
      return this.get('netAmount') + this.get('taxAmount');
    }),

    unit: Ember.computed('unitCode', function () {
      var code = this.get('unitCode');
      if (code) {
        return this.get('store').queryRecord('unit', { code: code });
      }
    }),

    taxRate: Ember.computed('taxRateCode', function () {
      var code = this.get('taxRateCode');

      if (code) {
        return this.get('store').queryRecord('tax-rate', { code: code });
      }
    })
  });
});