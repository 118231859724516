define('fakturama/adapters/currency', ['exports', 'fakturama/adapters/fixture'], function (exports, _fixture) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _fixture.default;
    }
  });
});