define('fakturama/routes/invoice/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('invoice');
    },
    activate: function activate() {
      var invoice = this.modelFor('invoice');
      document.title = invoice.get('number') + ' ' + invoice.get('buyerFirstLine') + ' - Fakturama';
    },
    deactivate: function deactivate() {
      document.title = 'Fakturama';
    }
  });
});