define('fakturama/models/invoice', ['exports', 'ember-data', 'fakturama/mixins/invoice-properties'], function (exports, _emberData, _invoiceProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend(_invoiceProperties.default, {
    itemsAttributes: attr(),
    number: attr('string'),
    issueDate: attr('string'),
    deliveryDate: attr('string'),
    dueDate: attr('string'),
    seller: attr('string'),
    buyer: attr('string'),
    currencyCode: attr('string'),
    languageCode: attr('string'),
    comment: attr('string'),
    sellerSignature: attr('string'),
    buyerSignature: attr('string'),
    exchangeRate: attr('number'),
    exchangeDate: attr('string'),
    exchangeDivisor: attr('number'),
    accountBankName: attr('string'),
    accountSwift: attr('string'),
    accountNumber: attr('string'),
    isPaid: attr('boolean')
  });
});