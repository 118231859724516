define('fakturama/mirage/factories/client', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    companyName: function companyName() {
      return _emberCliMirage.faker.company.companyName();
    },
    address: function address() {
      return _emberCliMirage.faker.address.streetAddress() + ', ' + _emberCliMirage.faker.address.city() + ', ' + _emberCliMirage.faker.address.country();
    },
    contactName: function contactName() {
      return _emberCliMirage.faker.name.firstName() + ' ' + _emberCliMirage.faker.name.lastName();
    }
  });
});