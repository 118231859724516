define('fakturama/routes/home', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    showLayout: false,
    renderTemplate: function renderTemplate() {
      this.render('home', { outlet: 'no-layout' });
    }
  });
});