define('fakturama/serializers/firebase', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      Object.assign(data, this.serialize(record, options));
    }
  });
});