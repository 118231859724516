define('fakturama/router', ['exports', 'fakturama/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('home', { path: '/' });

    this.route('clients', function () {
      this.route('new');
    });
    this.route('client', { path: 'client/:client_id' }, function () {
      this.route('edit');
    });

    this.route('accounts', function () {
      this.route('new');
    });
    this.route('account', { path: 'account/:account_id' }, function () {
      this.route('edit');
    });

    this.route('invoices', function () {
      this.route('new');
    });
    this.route('invoice', { path: 'invoice/:invoice_id' }, function () {
      this.route('show', { path: '/' });
      this.route('edit');
    });

    this.route('settings');
  });

  exports.default = Router;
});