define('fakturama/components/cents-field', ['exports', 'fakturama/lib/format_cents', 'fakturama/lib/parse_cents'], function (exports, _format_cents, _parse_cents) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultPrecision = 2;

  exports.default = Ember.Component.extend({
    tagName: 'input',

    attributeBindings: ['type', 'step', 'value', 'disabled'],

    type: 'number',

    precision: defaultPrecision,

    init: function init() {
      this._super.apply(this, arguments);
      this.assignValue();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.assignValue();
    },
    change: function change(event) {
      var value = event.target.value;

      var precision = this.get('precision');
      var cents = (0, _parse_cents.default)(value, precision);
      this.attrs.cents.update(cents);
    },


    step: Ember.computed('precision', function () {
      var precision = parseInt(this.get('precision') || defaultPrecision, 10);

      return String(1 / Math.pow(10, precision));
    }),

    assignValue: function assignValue() {
      var cents = this.get('cents');
      var precision = this.get('precision');
      this.set('value', (0, _format_cents.default)(cents, precision));
    }
  });
});