define('fakturama/mixins/invoice-properties', ['exports', 'fakturama/models/item', 'polish-to-words'], function (exports, _item, _polishToWords) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    sellerFirstLine: Ember.computed('seller', function () {
      return this.getWithDefault('seller', '').split('\n')[0];
    }),

    sellerRest: Ember.computed('seller', function () {
      return this.getWithDefault('seller', '').split('\n').slice(1);
    }),

    buyerFirstLine: Ember.computed('buyer', function () {
      return this.getWithDefault('buyer', '').split('\n')[0];
    }),

    buyerRest: Ember.computed('buyer', function () {
      return this.getWithDefault('buyer', '').split('\n').slice(1);
    }),

    commentLines: Ember.computed('comment', function () {
      return this.getWithDefault('comment', '').split('\n');
    }),

    periodNumber: Ember.computed('number', function () {
      var number = this.getWithDefault('number', '').match(/([^/]+)\/(.+)/);
      return number ? number[2] : 1;
    }),

    periodicalNumber: Ember.computed('number', function () {
      var number = this.getWithDefault('number', '').match(/([^/]+)\/(.+)/);
      return number ? parseInt(number[1], 10) : 0;
    }),

    items: Ember.computed('itemsAttributes', 'itemsAttributes.@each', function () {
      var _this = this;

      return this.getWithDefault('itemsAttributes', []).map(function (itemAttributes) {
        return _item.default.create(Object.assign({}, itemAttributes, { container: Ember.getOwner(_this) }));
      });
    }),

    currency: Ember.computed('currencyCode', function () {
      var code = this.get('currencyCode');
      if (code) {
        return this.get('store').queryRecord('currency', { code: code });
      }
    }),

    language: Ember.computed('languageCode', function () {
      var code = this.get('languageCode');
      if (code) {
        return this.get('store').queryRecord('language', { code: code });
      }
    }),

    subTotals: Ember.computed('items', 'items.@each.netAmount', 'items.@each.taxAmount', 'items.@each.grossAmount', 'items.@each.taxRate', 'exchangeRate', 'exchangeDivisor', function () {
      var _this2 = this;

      var results = Ember.ArrayProxy.create({ content: Ember.A([]) });

      Ember.RSVP.all(this.get('items').map(function (item) {
        return item.get('taxRate');
      })).then(function () {
        _this2.get('items').mapBy('taxRate').uniq().map(function (taxRate) {
          var items = _this2.get('items').filterBy('taxRate', taxRate);
          var result = Ember.Object.create({ taxRate: taxRate });

          result.set('netAmount', items.reduce(function (previousValue, item) {
            return previousValue + item.getWithDefault('netAmount', 0);
          }, 0));

          result.set('taxAmount', Math.round(result.get('netAmount') * result.get('taxRate.value') / 100));
          result.set('grossAmount', result.get('netAmount') + result.get('taxAmount'));

          if (_this2.get('exchangeRate')) {
            result.set('taxAmountPLN', Math.round(result.get('taxAmount') * _this2.get('exchangeRate') / (_this2.get('exchangeDivisor') * 10000)));
          }

          results.pushObject(result);
        });
      });

      return results;
    }),

    totalNetAmount: Ember.computed('subTotals', 'subTotals.@each.netAmount', function () {
      return this.get('subTotals').reduce(function (previousValue, item) {
        return previousValue + item.get('netAmount');
      }, 0);
    }),

    totalTaxAmount: Ember.computed('subTotals', 'subTotals.@each.taxAmount', function () {
      return this.get('subTotals').reduce(function (previousValue, item) {
        return previousValue + item.getWithDefault('taxAmount', 0);
      }, 0);
    }),

    totalGrossAmount: Ember.computed('subTotals', 'subTotals.@each.grossAmount', function () {
      return this.get('subTotals').reduce(function (previousValue, item) {
        return previousValue + item.get('grossAmount');
      }, 0);
    }),

    totalTaxAmountPLN: Ember.computed('totalTaxAmount', 'exchangeRate', 'exchangeDivisor', 'isExchanging', function () {
      if (this.get('isExchanging')) {
        return Math.round(this.get('totalTaxAmount') * this.get('exchangeRate') / (this.get('exchangeDivisor') * 10000));
      }
    }),

    totalGrossAmountPLN: Ember.computed('totalGrossAmount', 'exchangeRate', 'exchangeDivisor', 'isForeignCurrency', function () {
      if (this.get('isForeignCurrency')) {
        return Math.round(this.get('totalGrossAmount') * this.get('exchangeRate') / (this.get('exchangeDivisor') * 10000));
      } else {
        return this.get('totalGrossAmount');
      }
    }),

    totalGrossAmountInWords: Ember.computed('totalGrossAmount', 'currency.code', function () {
      var amount = String(this.get('totalGrossAmount'));
      var dollars = amount.substr(0, amount.length - 2) || '0';
      var cents = amount.substr(amount.length - 2, amount.length) || '0';

      return (0, _polishToWords.default)(dollars) + ' ' + this.get('currency.code') + ' ' + cents + '/100';
    }),

    englishTotalGrossAmountInWords: Ember.computed('totalGrossAmount', 'currency.code', function () {
      var dollars,
          cents,
          amount = String(this.get('totalGrossAmount'));

      dollars = amount.substr(0, amount.length - 2) || '0';
      cents = amount.substr(amount.length - 2, amount.length) || '0';

      return window.toWords(dollars) + ' ' + this.get('currency.code') + ' ' + cents + '/100';
    }),

    isEnglish: Ember.computed('languageCode', function () {
      return this.get('languageCode') === 'plen';
    }),

    isForeignCurrency: Ember.computed('currencyCode', function () {
      return this.get('currencyCode') !== 'PLN';
    }),

    isExchanging: Ember.computed('currencyCode', 'issueDate', 'totalTaxAmount', 'isForeignCurrency', function () {
      return !!this.get('currencyCode') && !!this.get('issueDate') && !!this.get('totalTaxAmount') && this.get('isForeignCurrency');
    }),

    isExpired: Ember.computed('dueDate', function () {
      return Date.parse(this.get('dueDate')) < new Date().getTime();
    }),

    isOverdue: Ember.computed('isExpired', 'isPaid', function () {
      return this.get('isExpired') && !this.get('isPaid');
    })
  });
});