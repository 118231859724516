define('fakturama/services/current-session', ['exports', 'fakturama/lib/firebase_auth', 'fakturama/models/user'], function (exports, _firebase_auth, _user) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      var authClass = this.getWithDefault('authClass', _firebase_auth.default);
      this.set('_auth', authClass.create());
    },
    setup: function setup() {
      var _this = this;

      if (this.getWithDefault('_setup', false)) {
        return Ember.RSVP.resolve();
      }

      return new Ember.RSVP.Promise(function (resolve) {
        var auth = _this.get('_auth');
        auth.onAuthStateChanged(function (user) {
          if (user) {
            return _this._setUser(user).then(resolve);
          } else {
            return _this.create().then(resolve);
          }
        });
        _this.set('_setup', true);
      });
    },


    currentUser: Ember.computed('userData.{user,token}', function () {
      var _get = this.get('userData'),
          user = _get.user,
          token = _get.token;

      return _user.default.create({
        uid: user.uid,
        authToken: token,
        email: user.email,
        displayName: user.displayName,
        isAnonymous: user.isAnonymous
      });
    }),

    create: function create() {
      var method = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'anonymous';

      var auth = this.get('_auth');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        switch (method) {
          case 'anonymous':
            auth.signInAnonymously().then(resolve, reject);
            break;
          case 'google':
            auth.signInWithGoogle().then(resolve, reject);
            break;
          default:
            reject('Session#create with ' + method + ' is not supported');
        }
      });
    },
    remove: function remove() {
      var _this2 = this;

      var auth = this.get('_auth');
      return auth.signOut().then(function () {
        return _this2.create();
      });
    },
    _setUser: function _setUser(user) {
      var _this3 = this;

      return user.getIdToken().then(function (token) {
        _this3.set('userData', { user: user, token: token });
      });
    }
  });
});