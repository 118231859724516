define('fakturama/services/dummy-session', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    setup: function setup() {
      return Ember.RSVP.resolve();
    }
  });
});