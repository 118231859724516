define('fakturama/mirage/config', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    // These comments are here to help you get started. Feel free to delete them.

    /*
      Config (with defaults).
       Note: these only affect routes defined *after* them!
    */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
      Shorthand cheatsheet:
       this.get('/posts');
      this.post('/posts');
      this.get('/posts/:id');
      this.put('/posts/:id'); // or this.patch
      this.del('/posts/:id');
       http://www.ember-cli-mirage.com/docs/v0.3.x/shorthands/
    */
    this.get('/clients.json', 'clients');
    this.post('/clients.json', 'clients');
    this.put('/clients/:id', function (_ref, request) {
      var clients = _ref.clients;

      var id = idWithoutJSONSuffix(request);
      var attrs = this.normalizedRequestAttrs();
      return clients.find(id).update(attrs.payload);
    });
    this.del('/clients/:id', function (_ref2, request) {
      var clients = _ref2.clients;

      var id = idWithoutJSONSuffix(request);
      return clients.find(id).destroy();
    });
  };

  var idWithoutJSONSuffix = function idWithoutJSONSuffix(request) {
    return request.params.id.split('.')[0];
  };
});