define('fakturama/models/client', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    companyName: attr(),
    address: attr(),
    vatin: attr(),
    contactName: attr(),
    contactEmail: attr(),
    gravatarURL: Ember.computed('contactEmail', function () {
      var md5 = window.md5(this.getWithDefault('contactEmail', ''));
      return '//www.gravatar.com/avatar/' + md5 + '?d=mm';
    }),
    buyer: Ember.computed('address', 'companyName', 'vatin', function () {
      var parts = [this.get('companyName'), this.get('address')];

      if (this.get('vatin')) {
        parts.push('NIP / VATIN: ' + this.get('vatin'));
      }

      return parts.join('\n').trim();
    })
  });
});