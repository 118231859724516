define('fakturama/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),

    user: Ember.computed.readOnly('session.currentUser'),

    isAlertDismissed: false,

    actions: {
      signIn: function signIn(method) {
        var _this = this;

        var session = this.get('session');
        session.create(method).then(function () {
          _this.clearCache();
          _this.send('refresh');
        }, function (error) {
          return alert(error.message);
        });
      },

      signOut: function signOut() {
        var _this2 = this;

        var session = this.get('session');
        session.remove().then(function () {
          _this2.clearCache();
          _this2.transitionToRoute('home');
        });
      },

      dismissAlert: function dismissAlert() {
        this.set('isAlertDismissed', true);
      }
    },

    clearCache: function clearCache() {
      this.get('store').unloadAll();
    }
  });
});