define('fakturama/models/settings', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var Settings = Model.extend({
    companyName: attr(),
    address: attr(),
    vatin: attr(),
    contactName: attr(),
    numerationTypeCode: attr(),
    dueDays: attr(),

    seller: Ember.computed('address', 'companyName', 'vatin', function () {
      var parts = [this.get('companyName'), this.get('address')];

      if (this.get('vatin')) {
        parts.push('NIP / VATIN: ' + this.get('vatin'));
      }

      return parts.join('\n').trim();
    })
  });

  exports.default = Settings;
});