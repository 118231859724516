define('fakturama/routes/invoice/edit', ['exports', 'fakturama/forms/invoice'], function (exports, _invoice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = this.get('store');

      return Ember.RSVP.hash({
        model: this.modelFor('invoice'),
        currencies: store.findAll('currency'),
        taxRates: store.findAll('tax-rate'),
        languages: store.findAll('language'),
        units: store.findAll('unit'),
        clients: store.findAll('client'),
        accounts: store.findAll('account')
      });
    },
    setupController: function setupController(controller, models) {
      models.model = _invoice.default.create({ model: models.model });
      controller.setProperties(models);
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor(this.routeName);
      controller.set('model', null);
    }
  });
});