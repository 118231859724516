define('fakturama/routes/clients/new', ['exports', 'fakturama/forms/client'], function (exports, _client) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.get('store').createRecord('client');
    },
    setupController: function setupController(controller, model) {
      controller.set('model', _client.default.create({ model: model }));
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor(this.routeName);
      var model = controller.get('model');
      controller.set('model', null);
      model.delete();
    }
  });
});