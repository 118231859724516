define('fakturama/models/item', ['exports', 'fakturama/mixins/item-properties'], function (exports, _itemProperties) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var attributes = ['description', 'quantity', 'unitCode', 'netPrice', 'netAmount', 'taxRateCode', 'taxAmount', 'grossAmount'];

  exports.default = Ember.Object.extend(_itemProperties.default, {
    store: Ember.inject.service('store'),
    toJSON: function toJSON() {
      return this.getProperties(attributes);
    }
  });
});