define('fakturama/initializers/session', ['exports', 'fakturama/services/current-session', 'fakturama/services/dummy-session'], function (exports, _currentSession, _dummySession) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    var sessionService = Ember.testing ? _dummySession.default : _currentSession.default;
    application.register('service:session', sessionService);
  }

  exports.default = {
    name: 'session',
    initialize: initialize
  };
});