define('fakturama/models/numeration-type', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var NumerationType = Model.extend({
    code: attr(),
    name: attr()
  });

  NumerationType.reopenClass({
    primaryKey: 'code',
    FIXTURES: [{ id: 1, code: 'month', name: 'miesięczny' }, { id: 2, code: 'year', name: 'roczny' }]
  });

  exports.default = NumerationType;
});