define('fakturama/services/firebase', ['exports', 'fakturama/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var databaseURL = _environment.default.APP.FIREBASE.databaseURL;
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    url: databaseURL,
    userId: Ember.computed.readOnly('session.currentUser.uid'),
    token: Ember.computed.readOnly('session.currentUser.authToken')
  });
});