define('fakturama/mixins/edit-controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    makeTransition: function makeTransition() {
      var transitionTo = this.get('transitionTo');
      if (Ember.typeOf(transitionTo) == 'function') {
        transitionTo.call(this);
      } else {
        this.transitionToRoute(transitionTo);
      }
    },

    actions: {
      saveRecord: function saveRecord() {
        var _this = this;

        var model = this.get('model');

        model.set('isSubmitted', true);
        model.save().then(function () {
          return _this.makeTransition();
        }, function () {
          return null;
        });
      },

      deleteRecord: function deleteRecord() {
        var _this2 = this;

        var model = this.get('model');

        model.delete(true).then(function () {
          return _this2.makeTransition();
        });
      }
    }
  });
});