define('fakturama/adapters/fixture', ['exports', 'ember-data-fixture-adapter'], function (exports, _emberDataFixtureAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberDataFixtureAdapter.default.extend({
    findRecord: function findRecord(store, model, id) {
      return this.find(store, model, id);
    },
    queryRecord: function queryRecord(store, model, query) {
      return this.findQuery(store, model, query);
    },
    queryFixtures: function queryFixtures(fixtures, query) {
      return fixtures.find(function (record) {
        var compareTo = Object.assign({}, record, query);
        return JSON.stringify(record) === JSON.stringify(compareTo);
      });
    }
  });
});