define('fakturama/forms/item', ['exports', 'ember-validations', 'fakturama/mixins/item-properties', 'fakturama/mixins/form'], function (exports, _emberValidations, _itemProperties, _form) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectProxy.extend(_emberValidations.default, _form.default, _itemProperties.default, {
    validations: {
      description: {
        presence: { if: 'invoiceForm.isSubmitted' }
      },
      quantity: {
        presence: { if: 'invoiceForm.isSubmitted' },
        numericality: { if: 'invoiceForm.isSubmitted' }
      },
      netPrice: {
        presence: { if: 'invoiceForm.isSubmitted' },
        numericality: { if: 'invoiceForm.isSubmitted' }
      },
      unitCode: {
        presence: { if: 'invoiceForm.isSubmitted' }
      },
      taxRateCode: {
        presence: { if: 'invoiceForm.isSubmitted' }
      }
    },

    description: Ember.computed.oneWay('model.description'),
    quantity: Ember.computed.oneWay('model.quantity'),
    netPrice: Ember.computed.oneWay('model.netPrice'),
    unitCode: Ember.computed.oneWay('model.unitCode'),
    taxRateCode: Ember.computed.oneWay('model.taxRateCode')
  });
});