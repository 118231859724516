define('fakturama/forms/settings', ['exports', 'ember-validations', 'fakturama/mixins/form'], function (exports, _emberValidations, _form) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.ObjectProxy.extend(_emberValidations.default, _form.default, {
    validations: {
      companyName: {
        presence: {
          if: 'isSubmitted',
          message: 'nie może być pusta'
        }
      },
      address: {
        presence: {
          if: 'isSubmitted',
          message: 'nie może być pusty'
        }
      },
      vatin: {
        presence: {
          if: 'isSubmitted',
          message: 'nie może być pusty'
        }
      },
      dueDays: {
        presence: {
          if: 'isSubmitted',
          message: 'nie może być pusty'
        },
        numericality: {
          if: 'isSubmitted',
          greaterThanOrEqualTo: 0,
          messages: {
            greaterThanOrEqualTo: 'nie może być ujemny'
          }
        }
      }
    },

    id: Ember.computed.oneWay('model.id'),
    companyName: Ember.computed.oneWay('model.companyName'),
    address: Ember.computed.oneWay('model.address'),
    vatin: Ember.computed.oneWay('model.vatin'),
    dueDays: Ember.computed.oneWay('model.dueDays'),

    initDueDays: function () {
      if (this.get('dueDays') === undefined) {
        this.set('dueDays', 14);
      }
    }.on('init')
  });
});