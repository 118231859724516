define('fakturama/components/import-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',

    classNames: ['btn', 'btn-default', 'btn-upload'],

    reset: function reset() {},


    actions: {
      fileChange: function fileChange(event) {
        var _this = this;

        var fileReader = new window.FileReader();
        if (event.target.files.length) {
          fileReader.onload = function (event) {
            _this.get('import')(event.target.result);
            _this.reset();
          };
          fileReader.readAsText(event.target.files[0]);
        }
      }
    }
  });
});