define('fakturama/adapters/application', ['exports', 'ember-data', 'ember-inflector'], function (exports, _emberData, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var RESTAdapter = _emberData.default.RESTAdapter;


  var host = Ember.testing ? '/' : Ember.computed.readOnly('firebase.url');
  var inflector = new _emberInflector.default(_emberInflector.default.defaultRules);
  var namespace = Ember.testing ? '' : Ember.computed.readOnly('firebase.userId');

  exports.default = RESTAdapter.extend({
    host: host,
    namespace: namespace,
    defaultSerializer: 'firebase',
    firebase: Ember.inject.service('firebase'),

    buildURL: function buildURL() {
      var url = this._super.apply(this, arguments) + '.json?auth=' + this.get('firebase.token');
      return url.startsWith('http') ? url : '/' + url;
    },
    createRecord: function createRecord(store, model) {
      return this._super.apply(this, arguments).then(function (payload) {
        var record = Object.assign({}, { id: payload.name });
        return _defineProperty({}, inflector.pluralize(model.modelName), record);
      });
    },
    findAll: function findAll(store, model) {
      return this._super.apply(this, arguments).then(function (payload) {
        var records = Object.keys(payload || {}).map(function (id) {
          return Object.assign({}, payload[id], { id: id });
        });
        return _defineProperty({}, inflector.pluralize(model.modelName), records);
      });
    },
    findRecord: function findRecord(store, model, id) {
      return this._super.apply(this, arguments).then(function (payload) {
        var record = Object.assign({}, payload, { id: id });
        return _defineProperty({}, inflector.pluralize(model.modelName), record);
      });
    },
    updateRecord: function updateRecord(store, model, snapshot) {
      return this._super.apply(this, arguments).then(function (payload) {
        var record = Object.assign({}, payload, { id: snapshot.id });
        return _defineProperty({}, inflector.pluralize(model.modelName), record);
      });
    }
  });
});