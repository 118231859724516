define('fakturama/lib/parse_cents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function parseCents(value, precision) {
    var integerPart,
        fractionalPart,
        parts = String(value).split('.');

    if (!isFinite(precision)) {
      precision = 2;
    }

    integerPart = parts[0] || '0';
    fractionalPart = parts[1] || '0';
    fractionalPart += new Array(precision + 1).join('0');

    return parseInt(integerPart + fractionalPart.substr(0, precision), 10);
  }

  exports.default = parseCents;
});