define('fakturama/routes/invoices/new', ['exports', 'fakturama/forms/invoice'], function (exports, _invoice) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = this.get('store');

      return Ember.RSVP.hash({
        model: store.createRecord('invoice'),
        currencies: store.findAll('currency'),
        taxRates: store.findAll('tax-rate'),
        languages: store.findAll('language'),
        units: store.findAll('unit'),
        settings: store.findRecord('settings', 'default'),
        clients: store.findAll('client'),
        accounts: store.findAll('account'),
        invoices: this.modelFor('invoices')
      });
    },
    setupController: function setupController(controller, models) {
      var currencyCode = models.currencies.get('firstObject.code');
      models.model.set('currencyCode', currencyCode);
      models.model = _invoice.default.create({ model: models.model });
      controller.setProperties(models);
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor(this.routeName);
      var model = controller.get('model');
      controller.set('model', null);
      model.delete();
    }
  });
});