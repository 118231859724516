define('fakturama/mixins/form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    model: Ember.computed.alias('content'),
    isSubmitted: false,

    save: function save() {
      var form = this,
          model = this.get('model');

      return this.validate().then(function () {
        model.setProperties(form.toJSON());
        return model.save();
      }, null);
    },

    delete: function _delete() {
      var persist = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var model = this.get('model');
      if (model.isDestroying || model.isDestroyed) {
        return Ember.RSVP.resolve(true);
      }

      if (model.get('isNew')) {
        return model.unloadRecord();
      } else if (persist) {
        return model.destroyRecord();
      }
    },


    toJSON: function toJSON() {
      return this.getProperties(Object.keys(this.get('model').toJSON()));
    },

    addErrors: function addErrors(errors) {
      var form = this;

      Object.keys(errors || {}).forEach(function (property) {
        form.set('errors.' + property, form.get('errors.' + property).concat(errors[property]));
      });
    }
  });
});