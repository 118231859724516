define('fakturama/routes/client/edit', ['exports', 'fakturama/forms/client'], function (exports, _client) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('client');
    },

    setupController: function setupController(controller, model) {
      controller.set('model', _client.default.create({ model: model }));
    },
    deactivate: function deactivate() {
      var controller = this.controllerFor(this.routeName);
      controller.set('model', null);
    }
  });
});