define('fakturama/routes/settings', ['exports', 'fakturama/forms/settings'], function (exports, _settings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var store = this.get('store');
      return Ember.RSVP.hash({
        model: store.findRecord('settings', 'default'),
        numerationTypes: store.findAll('numeration-type')
      });
    },

    setupController: function setupController(controller, models) {
      models.model = _settings.default.create({ model: models.model });
      models.isDeleteModalVisible = false;
      controller.setProperties(models);
    }
  });
});