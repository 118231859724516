define('fakturama/mixins/exchange-rate', ['exports', 'fakturama/models/exchange-rates-table'], function (exports, _exchangeRatesTable) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    prevIssueDate: null,

    issueDateDidChange: Ember.observer('issueDate', function () {
      var issueDate = this.get('issueDate');
      var prevIssueDate = this.get('prevIssueDate');

      // Hack to avoid re-assigning issueDate and exchange attributes if not needed
      if (issueDate && prevIssueDate !== issueDate) {
        this.set('prevIssueDate', issueDate);
        this.set('exchangeRateTable', _exchangeRatesTable.default.find(this.get('issueDate')));
      }
    }),

    exchangeRateCurrencyDidChange: Ember.observer('currencyCode', 'exchangeRateTable.pozycja', function () {
      var currency = this.getWithDefault('exchangeRateTable.pozycja', []).findBy('kod_waluty', this.get('currencyCode'));
      var exchangeDate = null;
      var exchangeRate = null;
      var exchangeDivisor = null;

      if (currency) {
        exchangeDate = this.get('exchangeRateTable.data_publikacji');
        exchangeRate = parseInt(currency.kurs_sredni.replace(',', ''), 10);
        exchangeDivisor = parseInt(currency.przelicznik, 10);
      }
      this.setProperties({
        exchangeDate: exchangeDate,
        exchangeRate: exchangeRate,
        exchangeDivisor: exchangeDivisor
      });
    })
  });
});