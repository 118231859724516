define('fakturama/components/export-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',

    attributeBindings: ['href', 'download'],

    classNames: ['btn btn-default'],

    firebase: Ember.inject.service('firebase'),

    click: function click() {
      var _get$getProperties = this.get('firebase').getProperties(['url', 'userId', 'token']),
          url = _get$getProperties.url,
          userId = _get$getProperties.userId,
          token = _get$getProperties.token;

      var date = new Date().toISOString().substr(0, 16).replace('T', '_');

      this.setProperties({
        href: url + '/' + userId + '.json?auth=' + token,
        download: 'fakturama-export-' + date + '.json'
      });
    }
  });
});