define('fakturama/models/language', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;


  var Language = Model.extend({
    code: attr(),
    name: attr()
  });

  Language.reopenClass({
    primaryKey: 'code',
    FIXTURES: [{ id: 1, code: 'pl', name: 'polska' }, { id: 2, code: 'plen', name: 'polsko-angielska' }]
  });

  exports.default = Language;
});