define('fakturama/mirage/serializers/application', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _emberCliMirage.RestSerializer.extend({
    embed: true,
    root: false,

    normalize: function normalize(payload) {
      var type = this.type;
      (true && !('type must be provided in the serializer') && Ember.assert(type, 'type must be provided in the serializer'));

      return _emberCliMirage.RestSerializer.prototype.normalize.call(this, _defineProperty({}, type, {
        payload: payload
      }));
    },
    serialize: function serialize() {
      var json = _emberCliMirage.RestSerializer.prototype.serialize.apply(this, arguments);
      if (json.id) {
        json.name = json.id;
      }
      if (Array.isArray(json)) {
        return json.reduce(function (memo, e) {
          memo[e.id] = e;
          return memo;
        }, {});
      } else {
        return json;
      }
    }
  });
});