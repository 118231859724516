define('fakturama/initializers/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    Ember.Route.reopen({
      // Show layout by default, can be opt-out in the route
      showLayout: true,
      setupController: function setupController() {
        this._super.apply(this, arguments);
        this.controllerFor('application').set('showLayout', this.get('showLayout'));
      }
    });
  }

  exports.default = {
    initialize: initialize
  };
});