define('fakturama/instance-initializers/inject-owner-to-form', ['exports', 'fakturama/mixins/form'], function (exports, _form) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  // Dirty hack to get owner symbol
  var OWNER = function () {
    var finder = {};
    Ember.setOwner(finder, 'owner');
    return Object.keys(finder)[0];
  }();

  function initialize(appInstance) {
    _form.default.reopen(_defineProperty({}, OWNER, appInstance));
  }

  exports.default = {
    initialize: initialize
  };
});