define('fakturama/controllers/invoices/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    invoices: Ember.computed('content.@each.isNew', function () {
      return this.get('content').filterBy('isNew', false);
    }),

    actions: {
      markAsPaidInvoice: function markAsPaidInvoice(invoice) {
        invoice.set('isPaid', true);
        invoice.save();
      },

      markAsUnpaidInvoice: function markAsUnpaidInvoice(invoice) {
        invoice.set('isPaid', false);
        invoice.save();
      }
    }
  });
});