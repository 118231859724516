define('fakturama/models/user', ['exports', 'md5'], function (exports, _md) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    emailMD5: Ember.computed('email', function () {
      return (0, _md.default)(this.getWithDefault('email', ''));
    }),

    gravatarURL: Ember.computed('emailMD5', function () {
      return '//www.gravatar.com/avatar/' + this.get('emailMD5') + '?d=mm';
    }),

    name: Ember.computed('displayName', 'email', function () {
      return this.get('displayName') || this.get('email') || 'Gość';
    })
  });
});