define('fakturama/controllers/invoice/edit', ['exports', 'fakturama/mixins/edit-controller'], function (exports, _editController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_editController.default, {
    transitionTo: function transitionTo() {
      var model = this.get('content.model');
      if (model.get('isDeleted')) {
        this.transitionToRoute('invoices');
      } else {
        this.transitionToRoute('invoice.show', model);
      }
    },

    settings: null,
    currencies: null,
    taxRates: null,
    languages: null,
    units: null,
    clients: null,
    accounts: null,

    isRemoveItemDisabled: function () {
      return this.get('items.length') <= 1;
    }.property('items.@each'),

    actions: {
      addItem: function addItem() {
        this.get('content').addItem();
      },
      removeItem: function removeItem(item) {
        this.get('items').removeObject(item);
      },
      chooseClient: function chooseClient(client) {
        this.get('model').setProperties({
          buyer: client.get('buyer'),
          buyerSignature: client.get('contactName')
        });
      },
      chooseAccount: function chooseAccount(account) {
        this.get('model').setProperties({
          accountBankName: account.get('bankName'),
          accountSwift: account.get('swift'),
          accountNumber: account.get('number')
        });
      }
    }
  });
});